<template>

</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        data() {

        },
        computed: {

        },
        methods: {

        },
        validations: {

        }
    }
</script>